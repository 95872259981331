import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Link } from "gatsby";
import { Helmet } from "react-helmet"

export default () => ( 
  <>
  <Helmet title="VUE - Visual Understanding Environment" defer={false} />
  <Layout>  
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-2xl xl:text-3xl font-bold leading-none">
            Visual Understanding Environment
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Flexible concept mapping tools for managing and integrating digital resources in support of teaching, learning and research.
          </p>
          <p className="mt-8 md:mt-12">
            <Link to="/download"><Button size="lg">Download</Button></Link>
          </p>
          <p className="mt-4 text-gray-600">Mac / Windows / Linux</p>
        </div>
        <div className="lg:w-1/2">
          <img src={HeroImage}/>
          <img src="https://img.shields.io/github/downloads/VUE/VUE/total?color=blue&label=VUE Downloads&style=for-the-badge"/>
        </div>
      </div>
    </section>   
  </Layout>
  </>
);
